<template>
  <div class="HotelDetail">
    <div v-if="getHotel">
      <div>
        <div class="HotelDetailBack" @click="goToHotelList">
          <ChevronLeft :size="20" />
          <span class="HotelDetailBackText">Back to hotel list</span>
        </div>
        <div class="HotelDetailTitle">Hotel information:</div>
        <div class="HotelDetailRow">Hotel name: {{ getHotel.name }}</div>
        <div class="HotelDetailRow">Hotel address: {{ getHotel.name }}</div>
        <div class="HotelDetailRow">
          Tariff: {{ nameTariff(getHotel.tariff_id) }}
        </div>
        <div class="HotelDetailTitle">Hotel settings:</div>
        <div class="HotelDetailRow">
          PWA: {{ getHotel.pwa ? "enabled" : "disabled" }}
        </div>
        <div class="HotelDetailRow">
          Telegram: {{ getHotel.telegram_enable ? "enabled" : "disabled" }}
        </div>
        <div class="HotelDetailRow">
          Custom domain: {{ getHotel.domain ? "enabled" : "disabled" }}
        </div>
        <div class="HotelDetailTitle">Hotel options:</div>
        <div class="HotelDetailRow">
          <span>Edit:</span>
          <Pencil :size="20" @click="changeItem(getHotel)"></Pencil>
        </div>
        <div class="HotelDetailRow">
          <span>Upload JSON:</span>
          <label :for="getHotel.id" style="cursor: pointer"
            ><Upload :size="20"></Upload
          ></label>
          <input
            type="file"
            :id="getHotel.id"
            style="display: none"
            v-on:change="setJson"
          />
        </div>
        <div class="HotelDetailRow">
          <span>Download JSON:</span>
          <Download :size="20" @click="getJson(getHotel.id)"></Download>
        </div>
        <div class="HotelDetailRow">
          <span>Download Images:</span>
          <ImageAlbum :size="20" @click="getDownloadZipImages(getHotel.id)" />
        </div>
        <div class="HotelDetailRow">
          <span>Delete:</span>
          <Delete :size="20" @click="showDeleteModal(getHotel.id)"></Delete>
        </div>
      </div>
    </div>
    <div v-else>Hotel with ID: {{ $route.params.hotel_id }} not found.</div>
    <HotelChangeModal
      :show="showEdit"
      :item="getHotel"
      @close="closeModal"
      @accept="editItem"
    />
    <DeleteConfirmModal
      :show="showDelete"
      @close="hideDelete"
      @accept="deleteItem"
    />
  </div>
</template>

<script>
import { hotelModule } from "../store/modules/hotelModule";
import { TariffEnums } from "@/enums/TariffEnums";
import Pencil from "vue-material-design-icons/Pencil.vue";
import Delete from "vue-material-design-icons/Delete.vue";
import HotelChangeModal from "@/components/modals/HotelChangeModal";
import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import Download from "vue-material-design-icons/Download.vue";
import Upload from "vue-material-design-icons/Upload.vue";
import ImageAlbum from "vue-material-design-icons/ImageAlbum.vue";
import ChevronLeft from "vue-material-design-icons/ChevronLeft.vue";

export default {
  name: "HotelDetailComponent",
  components: {
    DeleteConfirmModal,
    HotelChangeModal,
    Pencil,
    Delete,
    Download,
    Upload,
    ImageAlbum,
    ChevronLeft,
  },
  data() {
    return {
      showDelete: false,
      deleteItemID: "",
      showEdit: false,
    };
  },
  computed: {
    ...hotelModule.mapGetters(["getHotel", "getHotelListData"]),
  },
  methods: {
    ...hotelModule.mapActions([
      "getHotelList",
      "getDownloadZipImages",
      "getUploadJson",
      "setUploadJson",
      "deleteHotel",
      "editHotel",
    ]),
    ...hotelModule.mapMutations(["setHotel"]),
    setJson(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.setUploadJson({ hotel_id: e.target.id, file: files[0] });
    },
    getJson(id) {
      this.getUploadJson(id);
    },
    async editItem() {
      let req = {
        id: this.getHotel.id,
        hotel: this.getHotel,
      };
      await this.editHotel(req);
      this.showEdit = false;
    },
    async deleteItem() {
      await this.deleteHotel(this.deleteItemID);
      this.deleteItemID = "";
      this.showDelete = false;
      this.setHotel(null);
    },
    showDeleteModal(id) {
      this.showDelete = true;
      this.deleteItemID = id;
    },
    hideDelete() {
      this.showDelete = false;
      this.deleteItemID = "";
    },
    changeItem() {
      this.showEdit = true;
    },
    closeModal() {
      this.showEdit = false;
    },
    nameTariff(id) {
      if (id === TariffEnums.Free) {
        return "Free";
      } else if (id === TariffEnums.Standart) {
        return "Standart";
      } else if (id === TariffEnums.Profi) {
        return "Profi";
      } else {
        return "Unknown Tariff";
      }
    },
    goToHotelList() {
      this.$router.push("/hotels");
    },
  },
  async mounted() {
    if (!this.getHotelListData.length) {
      await this.getHotelList();
    }
    const candidate = this.getHotelListData.find(
      (hotel) => +hotel.id === +this.$route.params.hotel_id
    );
    if (candidate) {
      this.setHotel(candidate);
    } else {
      this.setHotel(null);
    }
  },
};
</script>

<style scoped lang="scss">
.HotelDetail {
  padding: 30px;
  display: flex;
  flex-direction: column;

  &Back {
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 150px;
    margin-bottom: 30px;
    &Text {
      margin-top: 2px;
    }
  }

  &Title {
    font-size: 16px;
    font-weight: bold;
  }
  &Row {
    padding-left: 5px;
    display: flex;
    & span {
      display: block;
      width: 150px;
    }
  }
}
</style>
